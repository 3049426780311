@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --bg-color: #222429;
  --primary-bg-color: #333740;
  --primary-color: #2c62f6;
  --text-color: #b1b8ca;
  --text-active: #ffffff;
  --button-hover-bg-color: #2b2e34;
  --border-color: #494d59;
  --dropdown-height: 0;
  --rotate-arrow: 0;
  --translate-value: 0;
  --list-opacity: 0;
  --transition-time: 0.2s;
  --transition-timing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --border-radius: 0.7rem;
  --list-button-height: 4.6rem;
  --floatingIconcon-size: 26;
  --floatingIconcon-top: 0;
  --floatingIconcon-left: 0;
}

.button {
  border: none !important;
  cursor: pointer !important;
  background-color: transparent !important;
  outline: none !important;
}

.svg {
  position: relative;
  height: 20px;
  width: 20px;
  top: 25%;
  float: right;
}

.textTruncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdownTitleIcon,
.dropdownArrow {
  display: inline-flex;
}

.dropdownTitle {
  margin: 0 auto 0 1.8rem;
  text-transform: capitalize;
}

.dropdownButton {
  display: flex;
  align-items: center;
  padding: 0 1.8rem;
}

.dropdownButton svg {
  transition: all var(--transition-time) var(--transition-timing);
  fill: var(--text-color);
}

.dropdownButton svg,
.dropdownButton span {
  pointer-events: none;
}

/* .dropdownButton:hover,
.dropdownButton:focus {
  color: var(--text-active);
} */

.dropdownButton:hover svg,
.dropdownButton:focus svg {
  fill: var(--text-active);
}
.mainButton:focus .svg {
  fill: #fff;
}
.mainButton .svg {
  fill: var(--text-color);
}

.mainButton {
  /* height: 5.2rem; */
  border-radius: var(--border-radius);
  color: var(--text-color);
  background-color: var(--primary-bg-color);
  border: 0.1rem solid var(--border-color);
  transition: all var(--transition-time) var(--transition-timing);
}

.mainButton:focus {
  color: #fff;
  border: 0.1rem solid var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(44, 98, 246, 0.4);
}

.mainButton .dropdownArrow {
  transition: transform var(--transition-time) var(--transition-timing);
  transform: rotate(var(--rotate-arrow));
  margin-left: 1.8rem;
}

.list-button {
  height: var(--list-button-height);
  transition: color var(--transition-time) var(--transition-timing);
  color: var(--text-color);
  overflow: hidden;
  cursor: none;
}

.dropdownListContainer {
  overflow: hidden;
  max-height: var(--dropdown-height);
  transition: max-height var(--transition-time) var(--transition-timing);
}

.dropdownListWrapper {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--primary-bg-color);
  border-radius: var(--border-radius);
  border: 0.1rem solid var(--border-color);
  position: relative;
}

ul.dropdownList {
  position: relative;
  list-style-type: none;
}

ul.dropdownList::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  height: var(--list-button-height);
  background-color: var(--button-hover-bg-color);
  transition: all var(--transition-time) linear;
  transform: translateY(var(--translate-value));
  border-radius: var(--border-radius);
  pointer-events: none;
}
ul.dropdownList:hover::before,
ul.dropdownList:hover ~ .floatingIconcon {
  opacity: 1;
}

li.dropdownListItem {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  opacity: var(--list-opacity);
  transition: opacity 0.8s var(--transition-timing);
}

.floatingIconcon {
  height: calc(var(--floatingIconcon-size) * 1px);
  width: calc(var(--floatingIconcon-size) * 1px);
  position: absolute;
  top: var(--floatingIconcon-top);
  left: var(--floatingIconcon-left);
  background-color: var(--border-color);
  border-radius: 1rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition-time) var(--transition-timing);
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.floatingIconcon svg {
  fill: var(--text-active);
}

.dropdownArrow.active {
  transform: rotate(180deg);
}

.dropdownItem:hover {
  background-color: #b4b5b9;
  transition: all 0.3s;
}

.toggler::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.toggler {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.toggler {
  overflow-y: scroll;
  transition: all 0.3s;
  max-height: 70vh;
}
.toggler.hide {
  max-height: 0;
}
