.listWrapper,
ol {
  list-style: none;
  font-family: "Grape Nuts", cursive;
  /* font-size: 2.4rem; */
  color: midnightblue;
}

.mainHeading {
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;
}
.listWrapper:not(.listWrapper .listWrapper) {
  grid-template-columns: max-content;
  z-index: 1;
  position: relative;
  perspective: 50vw;
  transform-style: preserve-3d;
}
.listWrapper:not(.listWrapper .listWrapper) .done {
  text-decoration: line-through;
}
.listWrapper:not(.listWrapper .listWrapper) > li {
  --levitate: 0;
  padding: 1.2rem 0.5rem;
  background-color: pink;
  position: relative;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s;
}
.listWrapper:not(.listWrapper .listWrapper) > li::after {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  mix-blend-mode: overlay;
  background-image: linear-gradient(to left, #ffe4b2, #c8b188);
}
.listWrapper:not(.listWrapper .listWrapper) > li:hover {
  --levitate: 1;
}
.listWrapper:not(.listWrapper .listWrapper) > li:hover::before {
  transform: translate3d(6rem, 1rem, -1px)
    scale(calc(1 - var(--levitate) * 0.1)) rotate(1.5deg);
  filter: blur(8px);
}
.listWrapper:not(.listWrapper .listWrapper) > li:hover::after {
  opacity: 0.6;
}
.listWrapper:not(.listWrapper .listWrapper) > li.big {
  background-color: lightgreen;
  /* padding: 1.6rem 2rem; */
  /* padding-bottom: 3rem; */
}
.listWrapper:not(.listWrapper .listWrapper) > li .listWrapper {
  margin-top: 0.5em;
}
.listWrapper:not(.listWrapper .listWrapper) > li .listWrapper li {
  z-index: 1;
}
.listWrapper:not(.listWrapper .listWrapper) > li .listWrapper li:first-letter {
  /* font-size: 1.4em; */
}
.listWrapper:not(.listWrapper .listWrapper) > li:not(:first-of-type) {
  margin-top: 3rem;
}
.listWrapper:not(.listWrapper .listWrapper) > li:nth-child(3n) {
  transform: translate3d(
      -5px,
      calc(var(--levitate) * -5px),
      calc(var(--levitate) * 3rem)
    )
    rotate(-0.6deg) rotateX(calc(var(--levitate) * 6deg))
    rotateY(calc(var(--levitate) * -3deg));
}
.listWrapper:not(.listWrapper .listWrapper) > li:nth-child(3n-1) {
  transform: translate3d(
      7px,
      calc(var(--levitate) * -5px),
      calc(var(--levitate) * 3rem)
    )
    rotate(-0.5deg) rotateX(calc(var(--levitate) * 6deg))
    rotateY(calc(var(--levitate) * -3deg));
}
.listWrapper:not(.listWrapper .listWrapper) > li:nth-child(3n-2) {
  transform: translate3d(
      0px,
      calc(var(--levitate) * -5px),
      calc(var(--levitate) * 3rem)
    )
    rotate(0.5deg) rotateX(calc(var(--levitate) * 6deg))
    rotateY(calc(var(--levitate) * -3deg));
}
.listWrapper:not(.listWrapper .listWrapper) > li::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.3s;
  height: calc(100% - 2px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  transform-origin: bottom left;
  transform: translate3d(8px, 2px, -1px) rotate(1deg);
  filter: blur(2px);
}

.taskText {
  font-family: "Grape Nuts", cursive;
}
.deleteIcon {
  height: 23px;
  width: 25px;
}
.colorSelector {
  display: inline-block;
  height: 30px;
  width: 40px;
}

.colorSelectorWrapper {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}

.taskInput {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
