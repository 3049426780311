:root {
  --purple: #6a1ed2;
  --purple-2: #691ed21c;
  --gray: #c395ff;
  --gray2: #4f4f4f;
  --gray3: #6f6c90;
  --gray4: #a2a2a2;
  --green: #1dca94;
  --offwhite: #f5edff;
}
.custom-full-width-child {
  position: relative;
  left: 50%;
  /* transform: translateX(-50%); */
  width: 100vw;
  margin-left: -50vw;
  /* padding-left: 50vw; */
  /* padding-right: 50vw; */
}

.font-jakarta,
.font-jakarta * {
  font-family: "Plus Jakarta Sans", sans-serif;
}
.font-indie,
.font-indie * {
  font-family: "Indie Flower", cursive;
}
.text-gray3 {
  color: var(--gray3);
}
.text-gray4 {
  color: var(--gray4);
}
.bg-gray3 {
  background-color: var(--gray3);
}
.text-offwhite {
  color: var(--offwhite);
}
.text-green {
  color: var(--green);
}
.bg-green {
  background-color: var(--green);
}
.bg-offwhite {
  background-color: var(--offwhite);
}
.bg-purple2 {
  background-color: var(--purple-2);
}
.text-purple2 {
  color: var(--purple-2);
}
.bg-purple {
  background-color: var(--purple);
}
.text-gray {
  color: var(--gray);
}
.text-gray2 {
  color: var(--gray2);
}
.text-purple {
  color: var(--purple) !important;
}
.fw-semibold {
  font-weight: 600;
}
.fs-8 {
  font-size: 12px;
}
.no-outline {
  outline: none !important;
  box-shadow: none !important;
}
.h-48 {
  height: 48px;
  border-radius: 8px;
}
.h-36 {
  height: 36px !important;
}
.with-border {
  padding-top: 2px;
  border-width: 2px !important;
  /* margin-bottom: 15px; */
  position: relative;
}
.with-border:focus-within {
  border: 2px solid var(--purple) !important;
}
.with-border:focus-within rect,
.with-border:focus-within path {
  stroke: var(--purple);
}
.mail-label {
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  margin-left: 5px;
}

.updated-btn {
  background: linear-gradient(284deg, #6a1ed2 30.01%, #b39fff 129.9%);
  border-radius: 8px;
  padding: 0;
  color: white !important;
  height: 48px;
  font-weight: bold;
  box-shadow: none !important;
  transition: all 0.3s;
}
.updated-btn:hover {
  background: linear-gradient(284deg, #5220a6 30.01%, #9966ff 129.9%);
}
.white-btn {
  box-shadow: none !important;
  border-radius: 8px;
  padding: 0;
  color: var(--purple);
  font-weight: bold;
  background-color: var("--offwhite");
}

/* navbar and footer */
.nav-mid {
  position: relative;
  left: 5%;
}
@media (max-width: 991px) {
  .nav-mid {
    left: 0;
  }
}
.footer-col-wrapper {
  display: flex;
  color: #5e5b8a;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-col * {
  color: #5e5b8a;
}
.footer-col {
  width: 17%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .footer-col-wrapper {
    gap: 20px;
  }
  .footer-col {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .footer-links-wrapper {
    width: 30%;
    margin: auto;
  }
}
/* navbar and footer end */

/* Login page */
.main-login-img {
  z-index: 2;
}
.login-left {
  position: relative;
}
@media (max-width: 767px) {
  .with-border {
    border-width: 1px !important;
  }
  .login-left {
    height: 25vw;
    margin-bottom: 8vw;
  }
  .login-right {
    width: 85% !important;
  }
  .main-login-img {
    position: absolute;
    top: 8vw;
    right: 10px;
    /* width: 40% !important; */
    min-width: 100px;
    width: 20vw !important;
  }
}
@media (max-width: 420px) {
  .login-left {
    margin-bottom: 18vw !important;
  }
}
@media (max-width: 320px) {
  .login-left {
    margin-bottom: 22vw !important;
  }
}
.login-logo {
  left: 55px;
  top: 15px;
}
.login-blur {
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
}
@media (max-width: 767px) {
  .login-logo {
    left: 15px;
  }
  .login-blur {
    height: 130%;
  }
}
.google-btn {
  box-shadow: none !important;
  transition: all 0.3s;
}
.google-btn:hover {
  background-color: #f1f1f1;
}
.main-login-wrapper {
  min-height: 100vh;
}
@media (max-width: 767px) {
  .main-login-wrapper {
    min-height: 0;
  }
}

/* Login page end */

/* homepage */
.home-start-explore img {
  transition: all 0.5s;
  position: relative;
}
.home-start-explore:hover img {
  margin-left: 15px;
}
.hero-text {
  font-size: 40px;
}
.home-feature-card {
  padding: 0 10px;
  max-width: 527px;
  height: 527px;
  margin: auto;
}
@media (max-width: 1400px) {
  .home-feature-card {
    height: 527px;
  }
}
@media (max-width: 1200px) {
  .home-feature-card {
    height: 456px;
  }
}
@media (max-width: 767px) {
  .home-feature-card {
    height: 516px;
  }
}
@media (max-width: 575px) {
  .home-feature-card {
    height: 95vw;
  }
}
@media (max-width: 1200px) {
  .hero-text {
    font-size: 36px;
  }
}
@media (max-width: 480px) {
  .hero-text {
    font-size: 7vw;
  }
}
.home-wrapper {
  gap: 100px;
}
.home-sec-1 {
  margin-top: 76px;
}
.home-sec2-heading {
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .home-wrapper {
    gap: 65px;
  }
  .home-sec-1 {
    margin-top: 41px;
  }
  .home-sec2-heading {
    margin-bottom: 65px;
  }
}
.home-sec1-img {
  border-radius: 32px;
}
@media (max-width: 400px) {
  .home-sec1-img {
    border-radius: 16px !important;
  }
}
.home-sec1-img {
  transition: all 0.3s ease-in;
}
.home-sec1-img.zoom-in:hover {
  transform: scale(1.2);
}
.home-sec1-img.zoom-out {
  transform: scale(1.2);
}
.home-sec1-img.zoom-out:hover {
  transform: scale(1);
}
/* checkbox */
.home-check {
  height: 0;
  width: 0;
}

.home-check + label {
  position: relative;
  display: flex;
  margin: 0.6em 0;
  align-items: center;
  color: #fff;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.home-check + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid #434343;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.home-check:checked + label > span {
  border: 2px solid #434343;
  animation: home-home-shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.home-check:checked + label > span:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 2px;
  border-right: 2px solid var(--purple) !important;
  border-bottom: 2px solid var(--purple) !important;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: home-home-checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
    forwards;
}

@keyframes home-home-shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes home-home-checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: var(--purple);
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.1em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 6px;
    height: 12px;
    border-color: var(--purple);
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
/* checkbox end */

.home-faq .accordion-item,
.home-faq .accordion-header {
  border: none !important;
}
.faq-heading {
  color: black;
  font-weight: normal;
}
.home-faq [aria-expanded="true"] .faq-heading {
  color: var(--purple);
  font-weight: bold;
}
.home-faq [aria-expanded="true"]::after {
  background-image: url(../../assets/updated/home/minus.svg) !important;
}
.home-faq .accordion-button {
  padding: 0;
}
.home-faq .accordion-body {
  padding: 0;
}
.accordion-button::after {
  background-image: url(../../assets/updated/home/plus.png) !important;
  background-size: contain;
  transition: none;
}
.home-wrapper .why-heading::after {
  height: 3px;
}
.why-description {
  line-height: 28px;
}
.faq-heading::after {
  content: "";
  display: block;
  margin-top: 20px;
  position: absolute;
  width: 100%;
  left: 0 !important;
  height: 1px;
  background-image: url(../updated/home/underline.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: todo-divider-anim 0.3s forwards;
}
@media (min-width: 1400px) {
  .faq-heading::after {
    left: -75px !important;
  }
}
.faq-heading.last-child::after {
  width: 0 !important;
}
.feedback-card {
  box-shadow: 0 3.77px 10.55px 0 #080f341c;
  border: 0;
  border-radius: 18px;
}
.feedback-back {
  z-index: 1;
  left: 0;
  bottom: -100px;
  filter: blur(100px);
}

/* home checkbox */
.home-todo-check {
  height: 0;
  width: 0;
}

.home-todo-check + label {
  position: relative;
  display: flex;
  margin: 0.6em 0;
  align-items: center;
  color: #fff;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.home-todo-check + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.home-todo-check:checked + label > span {
  border: 0.5em solid var(--purple);
  animation: home-shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.home-todo-check:checked + label > span:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -6px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: home-checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
    forwards;
}

@keyframes home-shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes home-checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: white;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.3em;
    height: 0.6em;
    border-color: white;
    transform: translate3d(0, -0.4em, 0) rotate(45deg);
  }
}
/* home checkbox end */

/* homepage end */

/* pricing page */
.pricing-bg-top {
  background: var(--purple);
}
.updated-btn-2 {
  background: linear-gradient(284deg, #6a1ed2 30.01%, #b39fff 129.9%);
  border-radius: 15px;
  color: white !important;
  height: 40px;
  font-weight: bold;
  box-shadow: none !important;
  transition: all 0.3s;
}

.updated-btn-2:hover {
  background: linear-gradient(284deg, #5220a6 30.01%, #9966ff 129.9%);
}
/* pricing page end */

/* main page */
.main-page-img > div {
  transition: all 0.3s;
}
.main-page-img > div:hover {
  transform: scale(1.05);
}
/* main page end */
