:root {
  --primary-clr: #262a41;
  --secondary-color: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
  --red-color: linear-gradient(93.8deg, #f40d0d 0%, #f23939 100%);
  --white-color: #ffffff;
  --gray-color: #7c82a0;
  --gray-color-2: #828282;
  --input-back: linear-gradient(93.49deg, #654ea3 0.39%, #eaafc8 101.63%);
  --nav-back: linear-gradient(300.59deg, #56ccf2 36.38%, #0e5cc6 87.62%);
  --nav-back-2: #7000ff;
  --primary-text: #b1d5ff;
  --secondary-text: #909090;
  --primary-btn: #262a41;
  --dark-color: #3c4054;
  --dark-color-2: #3a3c45;
  --upload-color: #9d50bb;
  --dark-secondary-color: #454545;
  --light-bg-color: #b4b5b9;
  --home-dark: #262a41;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
small {
  font-weight: 400;
  font-family: "poppins";
}
.display-3 {
  font-family: "poppins";
  font-weight: 700;
}
.active {
  font-weight: 700;
}
u {
  text-decoration: underline !important;
}
body,
.questions-carousel-wrapper {
  overflow-x: hidden;
  -ms-overflow-x: hidden; /* for Internet Explorer */

  /* background-color: var(--primary-clr); */
}
.text-purple {
  color: #6a1ed2;
}
.text-gray-2 {
  color: var(--gray-color-2);
}
.bg-gray-2 {
  background-color: var(--gray-color-2);
}
.bg-dark {
  background: var(--dark-color) !important;
}
.bg-secondary {
  background: var(--secondary-color);
}
.light-bg {
  background: #cfcfcf;
}
/* texts */
.primary-text {
  color: #b1d5ff;
}
.text-dark {
  color: var(--dark-color) !important;
}
.secondary-text {
  color: var(--secondary-text);
}
/* inputs */
input[type="checkbox"] {
  cursor: pointer;
}
.search-light {
  background: none;
  border: 0;
  border-radius: 10px;
  color: var(--dark-color) !important;
  position: relative;
  background-image: url(../images/icons/search-light.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 14px;
  border: 2px solid #96a0bd;
  border-radius: 15px;
}
.search-light::placeholder {
  color: var(--dark-color);
  padding-left: 20px;
}

.search-light[value]:not([value=""]) {
  background-image: none;
}

.search-light:focus {
  background: none;
  background-image: url(../images/icons/search-light.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 14px;
}
.search-input {
  background: #3c4054;
  border: 0;
  border-radius: 10px;
  color: #fff !important;
  position: relative;
  background-image: url(../images/icons/search.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 14px;
}
.search-input::placeholder {
  color: #b1b8ca;
  padding-left: 20px;
}

.search-input[value]:not([value=""]) {
  background-image: none;
}

.search-input:focus {
  border: 1px solid #2c62f6;
  background: #3c4054;
  background-image: url(../images/icons/search.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 14px;
}
.lg-input-placeholder::placeholder {
  font-size: 16px;
}
.lg-input-placeholder {
  padding-bottom: 14px !important;
}
.lg-input-placeholder[value]:not([value=""]) {
  padding-bottom: 11px !important;
}
.modal-input {
  padding-bottom: 9px !important;
}
.primary-input {
  height: 40px;
  background: #3c4054 !important;
  border-radius: 16px;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  color: #c6c6c6 !important;
}
.secondary-input {
  min-width: none !important;
  width: auto !important;
  background: none;
  border: none;
  color: var(--secondary-text);
}
.white-input {
  height: 40px;
  background: white !important;
  border-radius: 12px;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  color: #3c4054 !important;
}
.rename-input {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: white !important;
}
.text-input {
  height: 50px;
  /* background: #3c4054 !important; */
  background: var(--input-back);
  border-radius: 16px;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  /* color: #c6c6c6 !important; */
  color: white !important;
}
.main-input {
  background: var(--input-back);
  color: white !important;
  box-shadow: none !important;
  border-radius: 16px;
  outline: none !important;
  border: none !important;
}
.main-input::placeholder,
.text-input::placeholder {
  color: white !important;
}
.select-field {
  border: 1px solid #000000;
  border-radius: 13px;
  box-shadow: none !important;
  font-weight: 700;
  max-width: 170px;
}

/* buttons */
.new-folder-btn {
  height: 40px;
  background-color: #3c4054;
  border: 3px solid #464a58;
  transition: all 0.3s;
  border-radius: 10px !important;
  color: white !important;
  font-weight: bolder;
}
.acc-delete-btn {
  background: #ff4b4b;
  height: 40px;
  font-weight: bolder;
  color: white !important;
  border-radius: 16px;
  width: 50%;
  min-width: fit-content;
  white-space: nowrap;
}

.lg-btn {
  height: 50px !important;
}
.light-btn {
  transition: all 0.3s;
  background-color: var(--primary-text) !important;
  color: white !important;
  font-weight: bolder;
  border-radius: 16px !important;
  font-size: 14px !important;
  height: 40px;
}
.primary-btn {
  transition: all 0.3s;
  background-color: var(--primary-btn);
  color: white !important;
  font-weight: bolder;
  border-radius: 16px !important;
  font-size: 14px !important;
  height: 40px;
}
.primary-btn:hover {
  background: rgb(38, 42, 65, 0.8);
  border: 3px solid white;
}
.upload-btn {
  transition: all 0.3s;
  background: none;
  border-radius: 16px !important;
  color: white !important;
  font-weight: bolder;
  border: 1px solid var(--upload-color);
}
.secondary-btn.border-10 {
  border-radius: 10px !important;
}
.pay-btn {
  border: 0 !important;
  transition: all 0.3s;
  background: linear-gradient(284deg, #6a1ed2 30.01%, #b39fff 129.9%);
  border-radius: 12px !important;
  color: white !important;
  width: 100%;
  height: 50px;
}

.secondary-btn,
.secondary-btn-lg {
  border: 0 !important;
  transition: all 0.3s;
  background: var(--secondary-color);
  border-radius: 16px !important;
  color: white !important;
  font-weight: bolder;
}
.secondary-btn {
  width: 100%;
  height: 40px !important;
}
.secondary-btn-lg {
  width: 100%;
  min-height: 40px;
}
.secondary-btn:hover ::before,
.secondary-btn-lg:hover ::before,
.btn-import:hover ::before,
.acc-delete-btn:hover ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.2);
}
.pay-btn:hover ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
}
.login-btn:hover ::before {
  border-radius: 10px !important;
}
.dark-btn,
.dark-btn-lg {
  transition: all 0.3s;
  background: var(--dark-color) !important;
  border-radius: 16px !important;
  color: white !important;
  font-weight: bolder;
}
.dark-btn {
  height: 40px;
}
.dark-btn-lg {
  min-height: 40px;
}
.dark-btn:hover,
.dark-btn-lg:hover,
.upload-btn:hover {
  background: rgb(119, 125, 162, 0.5);
}
.activeLoading {
  /* min-height: 50px; */
}
.loading-text.hide {
  opacity: 0;
}
.loading-button {
  /* padding: 20px 50px; */
  /* font-size: 1.5rem; */
  cursor: pointer;
  border: 0px;
  position: relative;
  /* margin: 20px; */
  transition: all 0.25s ease;
  /* background: rgba(116, 23, 231, 1); */
  border-radius: 16px;
  color: #fff;
  overflow: hidden;
}
.loading-button.activeLoading .loading {
  visibility: visible;
  opacity: 1;
}
.loading-button .loading {
  opacity: 0;
  visibility: hidden;
}

.loading-4 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  /* background: rgba(116, 23, 231, 1); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  overflow: hidden;
}
.loading-4 .span1 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite;
  position: absolute;
}
.loading-4 .span2 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite 0.33s;
  position: absolute;
}
.loading-4 .span3 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite 0.66s;
  position: absolute;
}
@keyframes loading4 {
  0% {
    transform: translate(-40px) scale(0);
  }
  50% {
    transform: translate(0px) scale(1);
  }
  100% {
    transform: translate(40px) scale(0);
  }
}
.custom-file-input::-webkit-file-upload-button {
  background: #e6e6e6;
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
}

.btn-add {
  background: linear-gradient(90deg, #373737 0%, #555555 100%);
  border-radius: 16px;
  color: white !important;
  height: 40px;
}
.btn-import {
  background: linear-gradient(90.73deg, #048302 0.38%, #00ad50 97.81%);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  border: none;
  color: white !important;
  height: 40px;
}
.btn-select {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 16px;
  color: #2f80ed !important;
  height: 40px;
}
.btn-subscribe {
  color: white !important;
  background: #2f80ed;
  border: 1px solid #ffffff;
  border-radius: 16px;
  height: 40px;
}
/* messages */
.message-wrapper.hide {
  opacity: 0;
  transition: all 1s;
  /* animation: message-hide 1s forwards; */
}
.success-img {
  height: 10px;
  width: 10px;
}
.success-img-wrapper {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px !important;
  border-radius: 50%;
  background: #16c612;
}
.message-wrapper {
  max-width: 200px;
  min-width: fit-content;
  /* white-space: nowrap; */
  margin: auto;
}
.message-success {
  background: #d5f5d4;
  border-radius: 25px;
}
.text-success {
  color: #1dca94 !important;
}

.message-failure {
  background: #f5d4d4;
  border-radius: 25px;
}
.text-failure {
  color: #a40d24;
}

/* question bank */
.add-mindmap-modal .dropdown-text {
  max-width: none;
}
.add-mindmap-modal .dropdown-text > span {
  margin: 0;
}

.dropdown-text {
  max-width: 250px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subject-title {
  max-width: 200px;
  margin: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* select subject */

.question-bank-wrapper {
  width: 67%;
  margin: auto;
}
@media (max-width: 767px) {
  .question-bank-wrapper {
    width: 90%;
  }
}

.question-bank-add-box {
  min-width: 160px;
  min-height: 100px;
  border: 2px solid #ffffff;
  box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.29);
  border-radius: 35px;
}
.question-bank-navigate-box {
  min-width: 160px;
  min-height: 100px;
  background: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
  box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.29);
  border-radius: 35px;
}
.settings-wrapper {
  opacity: 0;
}
.question-bank-navigate-box:hover .settings-wrapper {
  opacity: 1;
}
.question-bank-navigate-box p {
  word-break: break-word;
}
.navigate-name-wrapper {
  overflow: hidden;
  border-radius: 35px;
}
.question-bank-navigate-box:hover .bank-setting {
  display: block;
}
.bank-setting {
  display: none;
}
/* progress bar */
.one,
.two,
.three,
.four {
  position: absolute;
  margin-top: -5px;
  z-index: 3;
  height: 23px;
  width: 23px;
  border-radius: 25px;
}
.progress-text p {
  position: absolute;
}
.progress-text p:nth-child(1) {
  left: 0%;
}
.progress-text p:nth-child(2) {
  left: 30%;
}
.progress-text p:nth-child(3) {
  left: 57%;
}
.progress-text p:nth-child(4) {
  left: 82%;
}

.one {
  left: 21%;
}
.two {
  left: 41%;
}
.three {
  left: 59%;
}
.four {
  left: 77%;
}
.progress {
  background: none;
}
.progress-bar {
  position: relative;
  background: #ced4da;
  width: 58%;
  left: 21%;
  height: 2px;
  margin: auto 0;
}
.progress-25,
.progress-50,
.progress-75 {
  position: absolute;
  height: 2px;
  top: 23px;
  z-index: 2;
  left: 23%;
  background-color: #1dca94;
}
.progress-25 {
  width: 19%;
}
.progress-50 {
  width: 37%;
}
.progress-75 {
  width: 55%;
}
.primary-color {
  background-color: #4989bd;
}
.success-color {
  background-color: #1dca94;
}
.danger-color {
  background-color: #d9534f;
}
.warning-color {
  background-color: #f0ad4e;
}
.info-color {
  background-color: #5bc0de;
}
.no-color {
  background: #f6f6f6;
  border: 1px solid #ced4da;
}

/* add questions */
.questions-table tr td:first-child {
  width: 43%;
}
.questions-table tr td:nth-child(2) {
  width: 40%;
}
.table-border-dark {
  border: 1px solid var(--dark-color) !important;
}
.btn-outline-light-mode:hover {
  color: var(--white-color) !important;
}
/* todolist */
.clear-text {
  color: rgb(148, 148, 148) !important;
  cursor: pointer;
}
.clear-text:hover {
  color: white !important;
}
.todo-check {
  height: 0;
  width: 0;
}

.todo-check + label {
  position: relative;
  display: flex;
  margin: 0.6em 0;
  align-items: center;
  color: #fff;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.todo-check + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.todo-check:checked + label > span {
  border: 0.5em solid gray;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.todo-check:checked + label > span:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -6px;
  /* left: 0.2em; */
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: white;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.4em;
    height: 0.7em;
    border-color: white;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
.send-arrow-icon {
  transition: all 0.3s;
  stroke: white;
}
.send-arrow-icon:hover {
  stroke: #951b5f;
}
.update-task-img {
  transition: opacity 0.3s;
  opacity: 0;
}
.update-task-img-wrapper:hover .update-task-img {
  opacity: 1;
}
.todo-task-handle-btns button img {
  width: 16px;
  height: 16px;
}
.add-todo-btn img {
  height: 25px;
  width: 25px;
}
.add-todo-btn-sm img {
  height: 16px;
  width: 16px;
}
.sub-task-wrapper {
  position: relative;
  animation: todo-animation 300ms forwards ease-in-out;
}
@keyframes todo-animation {
  from {
    opacity: 0;
    top: -10px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

/* view subjects */
.subjects-table tr td:first-child {
  width: 10%;
}
.subjects-table tr td:nth-child(2) {
  width: 75%;
}

/* revision */
.revision-table tr td:first-child {
  width: 20%;
}
.revision-table tr td:nth-child(2) {
  width: 80%;
}

/* navbar */
.profile-dropdown-link {
  transition: all 0.3s;
}
.profile-dropdown-link:hover {
  background: var(--nav-back-2);
  color: white !important;
}
.profile-dropdown-link:hover .profile-dropdown-icon {
  fill: #fff;
}
.profile-dropdown-link:hover .profile-dropdown-icon.mindmap-icon path {
  fill: #fff;
  stroke: #fff;
}
.profile-dropdown-icon {
  transition: all 0.3s;
  height: 18px;
}
.profile-dropdown-icon.mindmap-icon path {
  transition: all 0.1s;
  fill: black;
  stroke: black;
}
.profile-logout-icon {
  height: 18px;
  transition: all 0.3s;
  fill: white !important;
}
.btn-logout:hover .profile-logout-icon {
  fill: #000000 !important;
}
.main-logo-white,
.main-logo-grey {
  width: 80px;
}
.main-logo-white path,
.main-logo-grey path {
  transition: all 0.3s;
}
/* linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%) */
.main-logo-grey:hover path {
  /* fill: #9d50bb; */
  fill: #6a1ed2;
}

.main-logo-white:hover path {
  fill: #9d50bb;
}
.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}
.navbar-light .nav-item .nav-link {
  color: #454545 !important;
}
.navbar-light .nav-item .nav-link.active {
  color: var(--white-color) !important;
}
.navbar-light .nav-logo {
  color: #454545 !important;
}
.navbar-dark .nav-logo {
  color: white !important;
}
.nav-logo span {
  color: #00d1ff;
}
.nav-link.active span {
  font-weight: 600;
}
.glider.hide,
.home-glider.hide {
  display: none !important;
}
.nav-item {
  padding: 0 3px;
}
.glider,
.home-glider {
  /* left: 0; */
  top: 13%;
  position: absolute;
  display: flex;
  height: 30px;
  width: 140px;
  background: var(--secondary-color);
  z-index: 2;
  transition: 0.25s ease-out;
}
.glider {
  border-radius: 99px;
}
.home-glider {
  border-radius: 11px;
}
.nav-item {
  z-index: 3 !important;
}
.nav-item .nav-link {
  color: var(--white-color) !important;
}
.navbar-dropdown {
  position: absolute;
  left: -70px !important;
  top: 50px !important;
  width: 130px !important;
  min-width: 0 !important;
  border: none;
  background: #ffffff;
  box-shadow: 10px 15px 96px 7px rgba(0, 0, 0, 0.29) !important;
  border-radius: 16px;
}
.navbar-link {
  color: #959595;
  font-weight: 400;
}
.btn-logout {
  color: white;
  background: var(--input-back);
  /* background: #6a1ed2; */
  /* background: linear-gradient(180deg, #fff600 0%, #ffe600 100%); */
  border-radius: 0px 0px 13px 13px;
}
.btn-logout-sm {
  color: white;
  /* background: var(--input-back); */
  background: #6a1ed2;
  /* background: linear-gradient(180deg, #fff600 0%, #ffe600 100%); */
}
.nav-profile-link {
  background: var(--nav-back-2);
  font-size: 1.2rem;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

/* profile */
.profile-wrapper {
  min-height: 75vh;
}

/* dashboard */
.task-item {
  --text-color: #fff;
  --text-line-scale: 0;
  --text-x: 0px;
  --border-default: #7f7f85;
  --border-hover: #e4e4e7;
  --checkbox-active: #6e7bf2;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 340px;
  border-radius: 7px;
  overflow: hidden;
  transition: background 0.15s linear;
}
.task-item:not(.done):hover {
  --blur-x: 372px;
  --blur-o: 1;
  background: #2c2c31;
}
.task-item:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 6px;
  right: 100%;
  top: -11px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px var(--checkbox-active);
  transform: translateX(var(--blur-x, 0));
  opacity: var(--blur-o, 0);
  transition: opacity 0.15s, transform 1.2s;
}
.task-item.done {
  --checkbox-active: #444449;
  --flag-border: #444449;
  --text-color: #626268;
}
.task-item .text {
  display: block;
  letter-spacing: -0.05px;
  text-decoration: none;
  color: var(--text-color);
  position: relative;
  transform: translateX(var(--text-x)) translateZ(0);
  transition: color 0.25s;
}
.task-item .text:before {
  content: "";
  position: absolute;
  height: 1px;
  left: -2px;
  right: -2px;
  top: 50%;
  transform-origin: 0 50%;
  transform: translateY(-50%) scaleX(var(--text-line-scale)) translateZ(0);
  background: currentColor;
}
.task-item .text a {
  color: inherit;
  text-decoration: none;
}
.task-item .text a:hover {
  text-decoration: underline;
}
.task-item .checkbox {
  --active-tick: #fff;
  --checkbox-lines-offset: 13.5px;
  display: block;
  padding: 15px 12px;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
.task-item .checkbox svg {
  display: block;
  position: absolute;
}
.task-item .checkbox input {
  display: block;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
}
.task-item .checkbox input + svg {
  width: 21px;
  height: 18px;
  left: 12px;
  top: 15px;
  color: var(--checkbox-active);
  transition: color 0.25s linear;
}
.task-item .checkbox input + svg .shape {
  stroke-width: 1.5px;
  stroke: var(--border, var(--border-default));
  fill: var(--background, none);
  transition: fill 0.25s linear, stroke 0.25s linear;
}
.task-item .checkbox input + svg .tick {
  stroke-dasharray: 20;
  stroke-dashoffset: var(--checkbox-tick-offset, 20px);
  transition: stroke-dashoffset var(--checkbox-tick-duration, 0.15s)
    var(--checkbox-tick-easing, ease);
}
.task-item .checkbox input + svg .tick.mask {
  stroke: var(--active-tick);
}
.task-item .checkbox input + svg + svg {
  width: 11px;
  height: 11px;
  fill: none;
  stroke: var(--checkbox-active);
  stroke-width: 1.25;
  stroke-linecap: round;
  top: 9px;
  right: 2px;
  stroke-dasharray: 4.5px;
  stroke-dashoffset: var(--checkbox-lines-offset);
  pointer-events: none;
}
.task-item .checkbox input:checked + svg {
  --background: var(--checkbox-active);
  --border: var(--checkbox-active);
  --checkbox-tick-duration: 0.2s;
  --checkbox-tick-easing: cubic-bezier(0, 0.45, 1, 0.5);
  --checkbox-tick-offset: 0;
}
.task-item .checkbox:hover input:not(:checked) + svg {
  --border: var(--border-hover);
}
.task-item .flag {
  --flag-color: #babac1;
  --flag-fill: var(--checkbox-active);
  --flag-fill-percent: 0%;
  --flag-scale-x: 1;
  --flag-scale-y: 1;
  --flag-rotate: 0deg;
  --flag-circle-opacity: 0;
  --flag-circle-scale: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 15px 12px;
  margin: 0 0 0 auto;
  position: relative;
}
.task-item .flag.active {
  --flag-fill-percent: 150%;
}
.task-item .flag svg {
  display: block;
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 1;
  pointer-events: none;
  fill: none;
  stroke: var(--flag-border, var(--border, var(--border-default)));
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 7.66px 16.75px;
  transform: scale(var(--flag-scale-x), var(--flag-scale-y))
    rotate(var(--flag-rotate));
  transition: stroke 0.25s linear;
}
.task-item .flag svg .fill {
  stroke: var(--flag-fill);
  fill: var(--flag-fill);
  clip-path: circle(
    var(--flag-fill-percent) at var(--flag-fill-x, 7.66px)
      var(--flag-fill-y, 16.75px)
  );
  transition: fill 0.25s linear, stroke 0.25s linear;
}
.task-item .flag:hover {
  --border: var(--border-hover);
}
.task-item .flag:before {
  content: "";
  display: block;
  width: 24px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  left: 8px;
  opacity: var(--flag-circle-opacity);
  background: var(--flag-fill);
  transform: scale(var(--flag-circle-scale)) translateZ(0);
}

@media (min-width: 768px) {
  .todo-right-wrapper {
    height: 20vw;
  }
}
/* .todo-wrapper-left:before {
  content: "";
  display: block;
  margin-top: 10px;
  position: absolute;
  width: 100px;
  height: 600px;
  background-image: url(../images/icons/divider.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  transform: rotate(90deg);
} */
.subtask-handlers {
  opacity: 0;
}
.main-subtask:hover .subtask-handlers {
  opacity: 1;
}
.task-handlers {
  opacity: 0;
}
.main-task:hover .task-handlers {
  opacity: 1;
}
.main-task-wrapper {
  overflow: hidden !important;
}
.main-task {
  position: relative;
  transition: all 0.1s;
}
.sample-main-task-after:after {
  content: "";
  display: block;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  height: 1px;
  background-image: url(../images/icons/divider.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: sample-todo-divider-anim 0.3s forwards;
}
@media (max-width: 1199px) {
  .sample-main-task-after:after {
    animation: sample-todo-divider-anim-md 0.3s forwards !important;
  }
}
@media (max-width: 991px) {
  .sample-main-task-after:after {
    animation: sample-todo-divider-anim-sm 0.3s forwards !important;
  }
}

@keyframes sample-todo-divider-anim-sm {
  0% {
    width: 0;
    left: -20%;
  }
  30% {
    width: 30%;
  }
  70% {
    width: 50%;
  }
  100% {
    width: 100%;
    left: 5%;
  }
}

@keyframes sample-todo-divider-anim-md {
  0% {
    width: 0;
    left: -30%;
  }
  30% {
    width: 30%;
  }
  70% {
    width: 50%;
  }
  100% {
    width: 100%;
    left: -13%;
  }
}

@keyframes sample-todo-divider-anim {
  0% {
    width: 0;
    left: -20%;
  }
  30% {
    width: 30%;
  }
  70% {
    width: 50%;
  }
  100% {
    width: 100%;
    left: -20%;
  }
}

.main-task-after:after {
  content: "";
  display: block;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  height: 1px;
  background-image: url(../images/icons/divider.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: todo-divider-anim 0.3s forwards;
}
@keyframes todo-divider-anim {
  0% {
    width: 0;
    left: -20%;
  }
  30% {
    width: 30%;
  }
  70% {
    width: 50%;
  }
  100% {
    width: 100%;
    left: 5%;
  }
}
.vertical-timeline-element-title::after {
  content: "";
  display: block;
  margin-top: 10px;
  position: absolute;
  width: 50%;
  left: 2.5%;
  height: 1px;
  background-image: url(../images/icons/divider.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
}
.vertical-timeline {
  /* width: 40% !important; */
  max-width: 65vw;
  left: 3% !important;
}
.timeline-time {
  left: -80px;
  top: 35px;
  background: linear-gradient(115.77deg, #fff600 2.61%, #e9e100 100%);
  border-radius: 10px;
}
.timeline-date {
  left: -69px;
  top: 68px;
}
@media (max-width: 650px) {
  .vertical-timeline {
    left: 8% !important;
    max-width: 75vw;
  }
  .vertical-timeline time {
    font-size: 12px;
  }
  .vertical-timeline-element-content {
    margin-left: 25px;
  }
  .timeline-time {
    left: -60px;
  }
  .timeline-date {
    left: -47px;
  }
}
.vertical-timeline-element {
  margin-top: 0;
}

.main-task:hover {
  /* background: #4fc3f7; */
}
.dashboard-main-sub-heading {
  text-align: start;
  color: var(--gray-color);
}
.todo-wrapper {
  width: 70%;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .todo-wrapper {
    width: 100%;
  }
}

.Ocean {
  width: 12vw;
  height: 13vw;
  max-width: 200px;
  max-height: 220px;
  min-width: 150px;
  min-height: 160px;
  border: 2px solid #00b6ff;
  filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Wave {
  position: relative;
  top: 100%;
  width: 550%;
  animation-name: swell;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  fill: #4fc3f7;
}

@keyframes swell {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.remaining-wrapper {
  background: linear-gradient(151.61deg, #fff600 3.06%, #70ff00 93.65%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30%;
  width: 12vw;
  height: 13vw;
  max-width: 200px;
  max-height: 220px;
  min-width: 150px;
  min-height: 160px;
  filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.25));
}
@media (max-width: 991px) {
  .main-wrapper {
    /* top: -50px; */
  }
}

/* database */
.database-filters-wrapper {
  width: 50%;
}
.database-search-wrapper {
  width: 95%;
}
.database-search-main-wrapper {
  flex: 0.9;
}
@media (max-width: 767px) {
  .database-search-main-wrapper {
    flex: 1;
  }
  .database-filters-wrapper {
    width: 100%;
  }
  .database-search-wrapper {
    width: 100%;
  }
  .database-search {
    /* width: 84%; */
  }
}

/* upgrade plan */
.free-card {
  background: linear-gradient(162.59deg, #56ccf2 2.46%, #2f80ed 64.95%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.paid-card {
  color: #2f80ed !important;
  background: linear-gradient(161.84deg, #ffffff 0%, #f0f0f0 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.plan-card-img {
  height: 21px;
  width: 21px;
}
.bg-primary {
  background: #2f80ed !important;
}
.bg-light {
  background-color: var(--light-bg-color) !important;
}
.price-sub {
  font-size: 40%;
}
.price-main,
.price-sub {
  font-weight: 700;
  font-family: "Mukta";
}
.text-orange {
  color: #ff7a00;
}

/* modals */
.image-modal .modal-content {
  background: none !important;
  background-color: transparent !important;
}
.delete-modal .modal-content {
  background: white;
}
.modal-footer {
  border: none !important;
}
.modal-content {
  /* background-color: #f6f6f6; */
  background-color: var(--primary-clr);
}
.flatpickr-input {
  min-width: 93%;
}
input.flatpickr-input::before {
  position: absolute;
  width: 100%;
  white-space: nowrap;
  color: #ccc;
  content: attr(placeholder);
}
input[value]:not([value=""]) + input::before {
  content: "" !important;
}

/* login */
.reg-msg {
  bottom: -10px;
}
@media (max-width: 767px) {
  .reg-msg {
    bottom: -70px;
  }
}
.login-btn {
  border: none !important;
  border-radius: 10px !important;
}
.login-input {
  border-radius: 10px !important;
}
.login-input::placeholder {
  font-weight: bolder;
}
.lg-btn::placeholder {
  font-weight: bolder;
}
.login-logo {
  max-width: 60px;
}
.login-error {
  width: 50%;
}
@media (max-width: 767px) {
  .login-error {
    width: 100%;
  }
}
.login-form {
  background: var(--primary-clr);
}
.login-wrapper {
  /* background: var(--primary-clr); */
}

@media (max-width: 767px) {
  .login-wrapper {
    padding: 20px 0;
    border-radius: 1px;
  }
}

/* contact */
.contact-or:after,
.contact-or:before {
  background: #d3d3e2;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 33%;
  transform: translatey(-50%);
  width: 10%;
}

.contact-or:after {
  left: 55%;
}

.contact-or:before {
  right: 55%;
}

.contact-email {
  color: var(--dark-color);
  background: linear-gradient(115.77deg, #fff600 2.61%, #e9e100 100%);
  border-radius: 15px;
  transition: all 0.5s;
}

.contact-email:hover {
  background: linear-gradient(115.77deg, #ffffff 2.61%, #ffffff 100%);
  color: #0084cf;
}

.contact-links {
  height: 3vw;
  width: 3vw;
  min-width: 50px;
  min-height: 50px;
  transition: all 0.5s;
}
.contact-links:hover,
.footer-links:hover {
  filter: grayscale(100%);
}
.contact-wrapper {
  min-height: calc(100vh - 210px);
  /* background-image: url(../images/icons/contact-arrow.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -100%;
}

.notif-dropdown {
  width: 450px !important;
  left: -350px !important;
}
@media (max-width: 462px) {
  .notif-dropdown {
    width: 330px !important;
    left: -250px !important;
  }
}
@media (max-width: 365px) {
  .notif-dropdown {
    width: 300px !important;
    left: -200px !important;
  }
}
.notif-dropdown li {
  border-radius: 16px;
}

.notif-dropdown::-webkit-scrollbar {
  display: none;
}

.notif-title {
  font-size: 15px !important;
  /* color: #505050 !important; */
}
.notif-title * {
  font-size: 15px !important;
  /* color: #505050 !important; */
}

.delete-blog-icon {
  fill: var(--secondary-text);
  transition: all 0.3s;
}
.delete-blog-icon:hover {
  fill: #56ccf2;
}

/* updates */

.like-btn {
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  transition: 150ms all;
}
.like-btn svg {
  height: 15px !important;
}
.like-btn.dislike .icon svg.prime {
  animation: dislike 550ms forwards ease-in-out;
}

.like-btn.active .counter {
  color: #fff;
}
.like-btn.active .icon svg {
  color: #fd1d1d;
}
.like-btn.active .icon svg.prime {
  animation: like 550ms forwards ease-in-out;
}
.like-btn .counter {
  font-weight: 700;
  color: white;
  top: 2px;
  position: relative;
  transition: 150ms all;
}
.like-btn .icon {
  transition: 150ms all;
  position: relative;
  width: 2rem;
}
.like-btn .icon svg {
  color: white;
  position: absolute;
  top: -2px;
  left: 0;
  height: 1.2rem !important;
  padding-right: 1rem;
}
.like-btn .icon svg.normal {
  z-index: 1;
  opacity: 0.5;
}
.like-btn .icon svg.prime {
  z-index: 2;
}

@keyframes dislike {
  0% {
    color: #fd1d1d;
    transform: translate(0, 0%);
  }
  100% {
    color: #fd1d1d;
    transform: translate(0, 300%) scale(0);
  }
}

@keyframes like {
  0% {
    color: white;
    transform: translate(0, 0%);
  }
  100% {
    color: #fd1d1d;
    transform: translate(0, -300%) scale(0);
  }
}

/* bubbles animation */
.bubble-container {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
}
.bubble {
  stroke: rgba(255, 255, 255, 0.5);
  position: fixed;
  bottom: 0;
  border-radius: 50%;
  background: hsla(203, 75%, 69%, 0.5);
}
@keyframes bubbleUp {
  from {
    bottom: 0;
  }
  to {
    bottom: 100%;
  }
}

.bubble {
  animation-name: bubbleUp;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.todo-anim {
  transition: all 0.3s;
}
.todo-anim-small {
  transition: all 0.3s;
  scale: 0.9;
}
#bubbles.show {
  bottom: 0;
  animation: bubbles-anim 2s forwards;
}
#bubbles {
  overflow: hidden !important;
}
#bubbles.hide {
  /* animation: bubbles-anim-hide 2s forwards; */
}
@keyframes bubbles-anim {
  from {
    /* height: 0px; */
    top: 100%;
  }
  to {
    top: 0;
    /* height: 100%; */
  }
}

@keyframes bubbles-anim-hide {
  0% {
    top: 0;
    /* height: 100%; */
  }
  100% {
    top: -100%;
    /* height: 0; */
  }
  100% {
    visibility: hidden;
  }
}

.exam-date {
  border-radius: 30%;
  animation: date-anim-rev 0.5s linear forwards;
}

.exam-date-anim {
  animation: date-anim 0.5s linear forwards;
}

.rem-days {
  top: 0;
  border-radius: 30%;
  animation: rem-days-anim-rev 0.5s linear forwards;
}

.rem-days-anim {
  animation: rem-days-anim 0.5s linear forwards;
}

@keyframes date-anim {
  from {
    top: -95%;
  }
  to {
    top: 0;
  }
}

@keyframes date-anim-rev {
  from {
    top: 0;
  }
  to {
    top: -95%;
  }
}

@keyframes rem-days-anim {
  from {
    top: 95%;
  }
  to {
    top: 0;
  }
}

@keyframes rem-days-anim-rev {
  from {
    top: 0;
  }
  to {
    top: 95%;
  }
}

/* home page */
@font-face {
  font-family: moontime;
  src: url(../fonts/FontsFree-Net-MoonTime-Regular-1.ttf);
}
.sec-2-li {
  /* display: inline-block; */
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  padding-left: 20px;
  text-indent: -20px;
}

@media (max-width: 575px) {
  .home-sec-1-heading,
  .home-sec-1-sub-heading {
    line-height: 14px;
  }
  /* .home-sec-1-heading {
    font-size: 14px;
  }
  .home-sec-1-sub-heading {
    font-size: 12px;
  }
  .home-sec-1-sub-heading-2 {
    font-size: 8px;
  }
  .sec-2-list li {
    font-size: 12px;
  }
  .start-btn {
    font-size: 12px;
  } */
}

.text-dark-secondary {
  color: var(--dark-secondary-color);
}
.home-main-wrapper {
  overflow: hidden !important;
  /* background-color: #f5f5f5; */
}

.home-login-btn {
  transition: all 0.3s;
  border-radius: 11px !important;
  color: white !important;
  background: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
  box-shadow: 0px 14px 29px -4px rgba(0, 0, 0, 0.31);
  font-weight: bolder;
  height: 30px;
  padding: 0 20px;
}
.text-dark-secondary-wrapper * {
  color: var(--dark-secondary-color);
  font-weight: 800;
}
.sec-1-by {
  font-family: "moontime";
  background: linear-gradient(93.49deg, #654ea3 0.39%, #eaafc8 101.63%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.sec-1-iam {
  font-family: "Anton";
  transform: matrix(1, 0, -0.18, 0.98, 0, 0);
  line-height: 100.73%;
  color: #fff600;
  margin: 0;
  padding: 0;
}
.sec-1-iam-wrapper {
  border-radius: 8px;
  background: var(--dark-secondary-color);
}
.home-main-container {
  position: relative;
  left: -16vw;
}

@media (max-width: 991px) {
  .home-main-container {
    left: 0;
  }
}
.sec-2-main-wrapper {
  min-height: 87vh;
}
@media (max-height: 1000px) {
  .sec-2-main-wrapper {
    min-height: 86vh;
  }
}
@media (max-height: 833px) {
  .sec-2-main-wrapper {
    min-height: 82vh;
  }
}
.sec-2-wrapper {
  margin-bottom: 36vh;
  background: linear-gradient(
    92.07deg,
    #9733ee -0.25%,
    #da22ff 57.94%,
    #0f35ff 86.55%,
    #0fd4ff 99.12%
  );
  border-radius: 30px 0px 0px 30px;
  margin-left: 2rem;
  height: 33vh;
  min-height: 20rem;
}
@media (min-width: 768px) {
  .what-heading {
    position: relative;
    top: -20px;
  }
}

@media (max-width: 767px) {
  .sec-2-main-wrapper {
    min-height: 0 !important;
  }
  .sec-2-wrapper {
    margin-bottom: 0vh !important;
    background: linear-gradient(91.9deg, #9733ee -0.3%, #da22ff 99.72%);
    border-radius: 0px;
    margin-left: 0;
    border-radius: 0;
    margin-bottom: 0;
    min-height: 0 !important;
    height: auto !important;
  }
}
.sec-2-list {
  font-weight: bold;
  padding-top: 2rem;
  padding: 0;
}
.sec-2-list li {
  font-family: "montserrat";
  margin-bottom: 0.4rem;
  color: white;
  font-weight: bold;
  list-style: disc;
  list-style-position: inside;
}

.start-btn {
  background: linear-gradient(90.24deg, #524e6c 0.21%, #0d073e 98.57%);
  border-radius: 15px;
  border: 1px solid white;
  color: white !important;
  width: fit-content;
}

.card-1-img-wrapper {
  background: linear-gradient(
    93.49deg,
    rgba(101, 78, 163, 0.35) 0.39%,
    rgba(234, 175, 200, 0.35) 101.63%
  );
  border-radius: 11px;
  width: fit-content;
}

.card-2-img-wrapper {
  background: linear-gradient(
    4.14deg,
    rgba(86, 204, 242, 0.35) 6.67%,
    rgba(14, 92, 198, 0.35) 93.25%
  );
  border-radius: 11px;
  width: fit-content;
}

.card-3-img-wrapper {
  background: linear-gradient(
    93.8deg,
    rgba(124, 248, 111, 0.21) 0%,
    rgba(4, 186, 225, 0.21) 100%
  );
  border-radius: 11px;
  width: fit-content;
}
.explore-btn {
  background: linear-gradient(90.24deg, #524e6c 0.21%, #0d073e 98.57%);
  border-radius: 15px;
  border: 1px solid #2f80ed;
  color: white !important;
  width: fit-content;
}
.why-heading {
  /* color: #48cbff; */
}
.how-heading {
  color: #48ffb2;
}
.get-set-wrapper span {
  border-radius: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.home-mac {
  left: 60%;
}
.home-mac img {
  width: 100%;
  max-width: 38rem;
  min-width: 20rem;
}
@media (max-width: 836px) {
  .home-mac {
    top: 5rem;
    left: 45%;
  }
  .home-mac img {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .home-mac img {
    width: 50%;
    min-width: 12rem;
  }
}
@media (max-width: 575px) {
  .home-mac img {
    width: 40%;
  }
}
.home-vec-wrapper {
  overflow: hidden;
  -ms-overflow: hidden;
}
.home-dark-vec-wrapper-1 {
  right: 0;
  top: 2%;
}
.home-dark-vec-wrapper-2 {
  top: 0%;
  left: -40%;
}
@media (max-width: 991px) {
  .home-dark-vec-wrapper-1 {
    top: 180%;
  }
  .home-dark-vec-wrapper-2 {
    top: 75%;
  }
}
/* footer */

.footer-links {
  width: 40px;
  transition: all 0.5s;
}
.footer-list li {
  transition: all 0.3s;
}
.footer-list li:hover {
  color: #6e48aa;
}
.footer-main-heading {
  font-weight: 600;
  line-height: 126.23%;
  background: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.sub-footer-heading {
  color: var(--primary-clr);
}
.card-1:hover,
.card-2:hover,
.card-3:hover {
  transform: scale(1.04);
}
.card-1 {
  transition: all 0.3s;
  background: linear-gradient(
      93.49deg,
      rgba(101, 78, 163, 0.1) 0.39%,
      rgba(234, 175, 200, 0.1) 101.63%
    ),
    #ffffff;
}
.card-2 {
  transition: all 0.3s;
  background: linear-gradient(
      136.05deg,
      rgba(0, 240, 255, 0.1) 26.74%,
      rgba(0, 133, 255, 0.1) 99.72%
    ),
    #ffffff;
}
.card-3 {
  transition: all 0.3s;
  background: linear-gradient(
      93.8deg,
      rgba(124, 248, 111, 0.1) 0%,
      rgba(4, 186, 225, 0.1) 100%
    ),
    #ffffff;
}

.why-heading::after,
.how-heading::after {
  content: "";
  display: block;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  left: -4.5rem !important;
  height: 1px;
  background-image: url(../updated/home/underline.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: todo-divider-anim 0.3s forwards;
}
@media (max-width: 1399px) {
  .why-heading::after,
  .how-heading::after {
    left: 0rem !important;
  }
}
@media (max-width: 1199px) {
  .why-heading::after,
  .how-heading::after {
    left: 0rem !important;
  }
}
@media (max-width: 991px) {
  .why-heading::after,
  .how-heading::after {
    left: 0rem !important;
  }
}
@media (max-width: 767px) {
  .why-heading::after,
  .how-heading::after {
    left: 0rem !important;
  }
}
.get-set-line {
  height: 3.5rem;
  position: relative;
  left: 2rem;
}
/* home animation */
.home-bg-dark {
  background: var(--home-dark);
  /* overflow: hidden; */
  /* -ms-overflow: hidden; */
  top: -120px !important;
  margin-bottom: -120px;
}
.waves {
  top: -120px;
  position: relative;
  width: 100%;
  height: 10vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 40px;
  max-height: 70px;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .home-bg-dark {
    top: -50px !important;
    margin-bottom: -50px;
  }
  .waves {
    top: -50px;
  }

  .home-bg-dark {
    top: -32px !important;
  }
  .waves {
    top: -32px;
  }
}

/* Animation */
.parallax {
  display: block;
  height: 100px;
}
.parallax > use {
  display: block;
  height: 100px !important;
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
  fill: var(--home-dark);
}
/* .parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
  fill: var(--home-dark);
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
  fill: var(--home-dark);
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
  fill: var(--home-dark);
} */
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 767px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}

/* .user-nav-logo {
  position: relative;
  left: 60px;
  top: 0.9rem;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .user-nav-logo {
    margin-bottom: 30px;
    left: 12px;
    top: 12.5px;
  }
} */
@media (min-width: 991px) {
  .feature-bg-dark {
    /* border-radius: 20px; */
  }
}
.feature-bg-dark {
  background: var(--primary-clr);
}
.features-heading {
  font-style: italic;
  /* transform: matrix(1, 0, -0.18, 0.98, 0, 0); */
}

/* feature text animation */
.features-heading .Typewriter__wrapper {
  font-weight: bold;
}
.feature-main-heading {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #fff, #000, #fff);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
.feature-name-greeting {
  text-align: start;
}
.feature-name-wrapper {
  text-align: center !important;
}
@media (max-width: 496px) {
  .dashboard-main-sub-heading {
    text-align: center;
  }
  .feature-name-greeting {
    justify-content: center;
  }
  /* .feature-name-greeting {
    text-align: center;
  } */
  .feature-name-input {
    margin-left: 13%;
    width: 87%;
    /* text-align: center; */
    margin-top: 10px;
  }
}
.feature-name-input {
  background: none;
  border: 0;
  box-shadow: none;
  text-transform: uppercase;
}

/* pricing */
/* .main-pricing-wrapper {
  height: 50rem;
}
@media (max-width: 767px) {
  .main-pricing-wrapper {
    height: 75rem;
  }
} */
/* .pricing-bg-top {
  background: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
} */

.free-card-light {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #6a1ed2 !important;
}
.paid-card-light {
  background: linear-gradient(
    147.12deg,
    #ebd197 5.39%,
    #b48811 40.55%,
    #a2790d 68.44%,
    #bb9b49 95.71%,
    #ffffff 121.78%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: white !important;
}

.free-card-light,
.paid-card-light {
  transition: all 0.3s;
  position: relative;
}
.free-card-light:hover,
.paid-card-light:hover {
  transform: scale(1.04);
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
}
/* .btn-select-light {
  background: linear-gradient(93.8deg, #9d50bb 0%, #6e48aa 100%);
  border: 1px solid #ffffff;
  border-radius: 15px;
  color: white !important;
  height: 40px;
} */
.btn-subscribe-light {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 15px;
  height: 40px;
  color: #b48811 !important;
}
.btn-select-light,
.btn-subscribe-light {
  transition: all 0.3s;
}
/* .btn-select-light:hover {
  background: linear-gradient(93.8deg, #ba50ea 0%, #ba50ea 100%);
} */
.btn-subscribe-light:hover {
  background: #ffefca;
}
/* .free-card-light:hover {
  padding-top: 100px !important;
  transform: translateY(-60px);
}
@media (max-width: 767px) {
  .paid-card-light {
    margin-top: 30px;
  }
}
.paid-card-light:hover {
  padding-top: 100px !important;
  transform: translateY(-60px);
  background: linear-gradient(
    119.67deg,
    #ebd197 3.33%,
    #b48811 55.43%,
    #a2790d 96.75%,
    #bb9b49 137.18%,
    #ffffff 175.8%
  );
} */

/* privacy page */
.privacy-wrapper h5 {
  text-align: start !important;
  font-weight: 500;
  margin: 1.5rem 0;
}

/* manage users page */
.manage-users-table th {
  white-space: nowrap !important;
}

/* mindmap */
.node-context.img {
  transition: all 0.3s;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}
.node-context.img:hover {
  background-color: var(--secondary-text);
}
.node-add-btn {
  background-color: var(--dark-color);
  padding: 7px 7px;
}
.node-text-value.selected {
  background-color: var(--dark-color-2) !important;
}
.node-text-value:hover {
  /* background-color: var(--dark-color) !important; */
}
.root-node .node-text-value {
  background-color: var(--dark-color) !important;
}
.rd3t-link {
  stroke: var(--dark-color) !important;
}
.node-text-value {
  max-height: 100px;
  transition: all 0.1s;
  background-color: var(--dark-color) !important;
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
  overflow-y: scroll !important;
}
.node-text-value::-webkit-scrollbar {
  display: none;
}

/* sidebar */
.folder-edit:focus {
  box-shadow: none;
}
.folder-edit {
  resize: none;
  height: 40px;
}
.folder-dropdown-icon.rotate {
  transform: rotate(-90deg);
}
.mindmap-options-menu {
  background-color: #3c4054;
  border-radius: 8px;
}
.sidebar {
  overflow-x: visible !important;
  z-index: 9999;
  transition: all 0.3s;
  background-color: var(--home-dark);
  height: 100vh;
  align-items: start;
  left: 0;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
}
.sidebar-subwrapper {
  height: 45vh;
}
.folders-wrapper {
  overflow-y: scroll;
  height: 100%;
}
.sidebar-subwrapper::-webkit-scrollbar,
.folders-wrapper::-webkit-scrollbar {
  display: none;
}
.sidebar .database-search-wrapper {
  width: 100%;
}
.sidebar.hide {
  left: -300px;
  position: fixed;
}
.resizable-div {
  width: 100px;
  height: 200px;
  border: 1px solid #ccc;
  resize: both;
  overflow: auto;
}
.sidebar-toggle {
  transition: all 0.3s;
}
.sidebar.hide .sidebar-toggle {
  left: 85px;
  /* animation: toggle-anim 0.3s forwards; */
}
.sidebar.hide .sidebar-logo {
  left: 315px;
}
/* @keyframes toggle-anim {
  0% {
    left: 0;
  }
  70% {
    left: 40px;
  }
  100% {
    left: 85px;
  }
} */
@media (max-width: 991px) {
  .sidebar {
    position: fixed;
    max-width: 100vw;
    min-width: 0 !important;
  }
}

@media (min-width: 992px) {
  .sidebar.show {
    animation: sidebar-anim 0.3s forwards;
  }
}

@keyframes sidebar-anim {
  0% {
    position: fixed;
  }
  80% {
    position: relative;
  }
  100% {
    position: relative;
  }
}

/* mindmap main */
/* .react-flow__node-mindmap {
  background: #f6ad55;
  border-radius: 2px;
  border: 1px solid transparent;
  padding: 2px 5px;
  font-weight: 700;
} */
.react-flow__handle.target {
  opacity: 1;
  top: 45%;
  left: -1.5px;
  background-color: #000;
  transform: none;
  border-radius: 50%;
  border: none;
  width: 5px;
  height: 5px;
  min-width: 0;
  min-height: 0;
}

.react-flow__handle.source {
  opacity: 1;
  top: 45%;
  right: -1.5px;
  background-color: #000;
  transform: none;
  border-radius: 50%;
  border: none;
  width: 5px;
  height: 5px;
  min-width: 0;
  min-height: 0;
}
.react-flow .react-flow__connectionline {
  /* width: 1px; */
  z-index: 0;
}
.react-flow path {
  stroke: gray !important;
  stroke-width: 2 !important;
}
.node-input {
  background: none;
  stroke: gray;
  stroke-width: 10px;
  color: #000;
  border: 0;
  height: 60px;
  /* padding-top: 15px; */
  border: 2px solid gray;
}
.node-input:focus {
  box-shadow: none;
  background: none;
  border: 0;
  border: 2px solid gray;
  color: #000;
}
.node-input.disabled {
  background: none;
  cursor: grab;
  caret-color: transparent;
}
.node-input.selected {
  border: 2px solid black;
}
.node-input::-webkit-scrollbar-track-piece {
  display: none;
}
.node-input-wrapper {
  border: 2px solid gray;
}
.react-flow__controls {
  width: 100%;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
}

.save-export {
  border-radius: 20px;
}
.node-input.disabled::selection {
  background: transparent !important;
}

.resizable-div:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  position: absolute;
}
.resizable-div.nodrag:empty:before {
  color: gray;
}

.resizable-div:focus:empty {
  caret-color: transparent;
}

.resizable-div:focus:empty::after {
  content: "";
  display: inline-block;
  width: 0.1ch;
  height: 20px;
  vertical-align: text-bottom;
  background: black;
  animation: blink 0.85s steps(2) infinite;
}

.resizable-div:focus::after {
  display: none;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
}
.react-flow__resize-control.handle {
  height: 7px !important;
  width: 7px !important;
  border-radius: 50% !important;
}

/* checkout page */
.checkout-card {
  max-width: 600px;
  background-color: #fbf8ff;
  padding: 40px;
  text-align: start;
  border-radius: 0.8rem;
}
@media (max-width: 400px) {
  .checkout-card {
    padding: 20px;
  }
}
/* checkout page end */
