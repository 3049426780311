.revisionWrapper {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* {
  box-sizing: border-box;
}
.carousel {
  position: relative;
  width: 40vw;
  height: 28vw;
  max-width: 380px;
  max-height: 380px;
  min-width: 300px;
  min-height: 300px;
  perspective: 500px;
  margin-bottom: 30px;
  transform-style: preserve-3d;
}
@media (max-width: 374px) {
  .carousel {
    min-width: 0;
    width: 70vw;
  }
}
.cardContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.cardContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: #000;
  border-radius: 1rem;
  color: #9ca3af;
  text-align: justify;
  transition: all 0.3s ease-out;
}
.card h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 0.7em;
  color: #1f2937;
}
.card p,
.card h2 {
  transition: all 0.3s ease-out;
  opacity: var(--active);
}
.nav {
  color: white;
  /* font-size: 5rem; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  height: 10vw;
  width: 10vw;
  max-width: 100px;
  max-height: 100px;
  min-width: 40px;
  min-height: 40px;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
}
.nav.left {
  transform: translateX(-100%) translatey(-50%);
}
.nav.right {
  right: 0;
  transform: translateX(100%) translatey(-50%);
}
@media (max-width: 430px) {
  .nav.left {
    left: 10px;
  }
  .nav.right {
    right: 10px;
  }
}
.revCard {
  background: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
  -ms-overflow: hidden;
}

.revCardHeader {
  border-radius: 15px 15px 0px 0px;
}

.revCardQues {
  display: none;
}
.revCardQues.active {
  display: block;
  animation: answer-anim 0.3s linear;
}
.revCard {
  min-height: 100%;
  overflow-x: hidden;
}
.divider {
  display: none;
}
.divider.show {
  display: block;
  animation: divider-anim 0.3s linear;
}
.revCardAns {
  display: none;
}
.revCardAns.show {
  display: block;
  animation: answer-anim 0.3s linear;
}
@keyframes answer-anim {
  from {
    opacity: 0;
    transform: translateY(300%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes divider-anim {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 0.3;
    transform: translateX(0);
  }
}
